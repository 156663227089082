<template>
  <div class="Affects">
    <page-top type="3"/>
    <clock/>
    <div class="content"></div>
    <div class="boxes">
      <div class="box">
        <div class="boxTop">累计登录量（近30日）</div>
        <div class="boxNum">{{quantitylog}}</div>
      </div>
      <div class="box">
        <div class="boxTop">授课端<br/>设备端登录量（近30日）</div>
        <div class="boxNum">{{lessonQuantitylog}}</div>
      </div>
      <div class="box">
        <div class="boxTop">windows/平板<br/>登录量（近30日）</div>
        <div class="boxNum">{{windowsQuantitylog}}</div>
      </div>
      <div class="box">
        <div class="boxTop">课件容量</div>
        <div class="boxNum">{{coursewareCapacity}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTop from "../components/PageTop";
import Clock from "../components/Clock";
import {onMounted, onUnmounted, reactive, toRefs} from "vue";
import service from "../utils/axios";
import {changePage} from "../utils/getPageTime";
import {useRouter} from "vue-router";
export default {
  name: "Affects",
  components: {Clock, PageTop},
  setup(){
    let router = useRouter()
    let state = reactive({
      coursewareCapacity: "",
      lessonQuantitylog: 0,
      quantitylog: 0,
      windowsQuantitylog: 0,
      timeout: null,
    })

    const getData = async () => {
      const {coursewareCapacity, lessonQuantitylog, quantitylog, windowsQuantitylog} = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/ATSFunction');
      state.coursewareCapacity = coursewareCapacity;
      state.lessonQuantitylog = lessonQuantitylog;
      state.quantitylog = quantitylog;
      state.windowsQuantitylog = windowsQuantitylog;
      state.timeout = await changePage(router,'ATS功能')
    }

    onUnmounted(()=>{
      state.timeout && clearTimeout(state.timeout)
    })

    onMounted(()=>{
      getData()
    })

    return {
      ...toRefs(state),
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.Affects {
  @include pageMix;
  .content{
    width: 1826px;
    height: 756px;
    @include backgroundMix;
    background-image: url("../assets/images/affects/affect_content.png");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    //mix-blend-mode: color-dodge;
    @media (max-width: 1280px){
      width: 1217px;
      height: 504px;
    }
  }

  .boxes{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 1280px){
      bottom: 60px;
    }
    .box{
      width: 200px;
      height: 170px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      @media (max-width: 1280px){
        width: 133px;
        height: 113px;
      }
      ::after{
        content: "";
        @include backgroundMix;
        background-image: url("../assets/images/affects/affect_box.png");
        opacity: 0.8;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    }
    .box+.box{
      margin-left: 30px;
    }
    .boxTop{
      font-size: 18px;
      font-family: Source Han Sans CN;
      color: #28F7FF;
      @media (max-width: 1280px){
        font-size: 12px;
      }

    }
    .boxNum{
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 5px;
      @media (max-width: 1280px){
        font-size: 20px;
      }
    }
  }
}
</style>
